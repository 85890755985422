import React, {Component} from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { HashLink } from 'react-router-hash-link';

import './index.scss';

import logo from '../../../images/merit-mile-logo.svg';

class HeaderLPOne extends Component {

    constructor(props) {
		super(props);
		// this.props.loadMenu(api.Menus.bySlug('main'));
		this.buildMenu = this.buildMenu.bind(this);
        this.state = {
            
        };
        this.removeMenu = this.removeMenu.bind(this);

        this.modalRef = React.createRef();
        this.openModal = this.openModal.bind(this);    
        this.modalSearchRef = React.createRef();    
        this.openModalSearch = this.openModalSearch.bind(this);
	}

    openModal() {
        this.modalRef.current.handleOpen();
    };

    openModalSearch() {
        this.modalSearchRef.current.handleOpen();
    };

	buildMenu() {
		if (this.props.mainMenu) {
			return this.props.mainMenu.map((item, i) => {
				return (
					<HashLink key={item.ID} to={item.url}>{item.title}</HashLink>
				);
			})
		}

		return null;
	}    

    scrollWithOffset = (el, offset) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: "smooth"
        });
    };

    removeMenu() {
        this.setState({ active: false });
    }

	render() {
        return (
            <header className="header-main" id="header">
                <nav className="navbar navbar-light bg-white">
                    <Link className="navbar-brand mr-auto" to="/"><img src={logo} className="img-fluid" width="410" height="62" alt="Merit Mile, LLC" /></Link>
                    <Link id="btnLink"
                        to="/contact-us/" >
                        Get In Touch
                    </Link>
                </nav>
            </header> 
        )
    }
}

HeaderLPOne.propTypes = {
    siteTitle: PropTypes.string,
}

HeaderLPOne.defaultProps = {
    siteTitle: ``,
}

export default HeaderLPOne
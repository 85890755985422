/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";

import 'bootstrap/dist/css/bootstrap.min.css';

import styled, {createGlobalStyle} from 'styled-components'

import HeaderLPOne from './layout/HeaderLPOne';
import Footer from './layout/Footer';

import CookieConsent from "react-cookie-consent";

const GlobalStylesLPOne = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Work+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
  body {
    font-family: 'Work Sans', sans-serif;
    margin: 0 !important;
    padding: 0 !important;
    color: black;
  }
`

const AppWrapperLPOne = styled.div`
  position: relative;
`

const LayoutWrapperLPOne = styled.div`
  
`

const LayoutLPOne = ({ children }) => (
  <AppWrapperLPOne>
    <GlobalStylesLPOne />
    <HeaderLPOne />
    <LayoutWrapperLPOne>
      {children}
    </LayoutWrapperLPOne>
    <Footer />
    <CookieConsent
        location="bottom"
        buttonText="ALLOW ALL COOKIES"
        cookieName="gatsby-gdpr-google-tagmanager"
        containerClasses="cookieConsentStyles"
        contentClasses="cookieConsentContentStyles"
        overlayClasses=""
        buttonClasses="acceptButtonStyles"
        expires={150}
        enableDeclineButton
        disableStyles
        flipButtons
        declineButtonText="Decline"
        declineButtonClasses="rejectButtonStyles"
      >
        <span className="cookieConsentContentHeadingStyles">
          <strong
            className="cookieConsentContenBoldStyles">
            About cookies on this site
          </strong>
        </span>
        <span className="cookieConsentContentSubHeadingStyles">
          We use cookies to collect and analyze information on site performance and usage, to provide social media features and to enhance and customize content and advertisements. <a href="https://www.meritmile.com/cookies/" style={{ color: "#fff"}}>Learn more</a>
        </span>
      </CookieConsent>
  </AppWrapperLPOne>
)

export default LayoutLPOne

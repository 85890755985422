import React, {Component} from 'react';

import $ from 'jquery';
import Cookies from 'js-cookie';

import LayoutLPOne from '../components/layoutLPOne';
import SEO from "../components/seo";

import FormField from '../components/utilities/FormField';

import './csp.scss';

class CSPForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        };

        this.submit = this.submit.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.emailChange = this.emailChange.bind(this);
        this.phoneChange = this.phoneChange.bind(this);
        this.companyChange = this.companyChange.bind(this);
    }

    submit(e) {
        e.preventDefault();
        let formObject = $('#heroForm');
        let postDt = new Date();
        let loadDt = new Date();
        //let _sid = 'qq3eu25egawyabolsjpyzcr5';
        let _sid = Cookies.get('ASP.NET_SessionId');
        if (formObject.find('input[name="js"]').length === 0) {
            let str = '<input type="hidden" name="js" value="1" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="ttp"]').length === 0) {
            let str = '<input type="hidden" name="ttp" value="' + ((postDt - loadDt) / 1000).toString() + '" />';
            formObject.append(str);
        }
        if (formObject.find('input[name="sid"]').length === 0) {
            let str = '<input type="hidden" name="sid" value="' + _sid + '" />';
            formObject.append(str);
        } else {
            formObject.find('input[name="sid"]').val(_sid);
        }

        let post_data = formObject.serialize();

        // console.log(post_data);

        this.setState({
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactCompany: ''
        });

        $.ajax({
            url: "https://metrix.meritmile.com/email.aspx?ajax=1",
            type: 'POST',
            data: post_data,
            cache: false,
            dataType: 'json',
            success: function(data) {
                // Success..
                // let r = data[0];
                // let redirect_url = "https://www.meritmile.com/uploads/2019/01/Merit-Mile-Industry-Pulse-2019-Outdoor-Retail-Industry-Trends.pdf";

                // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
                // window.location.href = redirect_url;

                $('#heroForm').hide();
                $('.form-wrapper .thankyou').show();
                window.open(
                    'https://mm-stats.com/bu0py',
                    '_blank' // <- This is what makes it open in a new tab.
                );
            },
            // Fail..
            error: function(result) {
                let r = result[0];

                formObject.find('.feedback').show().addClass('alert-error').html(r.Message).css('display', 'block').focus();
                formObject.find('.submit').attr('disabled', false).removeClass('disabled');
            }
        });
    }

    nameChange(e){
        this.setState({contactName: e.target.value})
    }

    emailChange(e){
        this.setState({contactEmail: e.target.value})
    }

    phoneChange(e){
        this.setState({contactPhone: e.target.value})
    }

    companyChange(e){
        this.setState({contactCompany: e.target.value})
    }

    render() {
        return (
            <form id="heroForm" name="metrixform" method="POST" action="https://metrix.meritmile.com/email.aspx" onSubmit={this.submit}>
                <FormField fieldID={"namehero"} valChange={this.nameChange} val={this.state.contactName} type={"text"}  name={"name"} label={"Full Name"}/>
                <FormField fieldID={"emailhero"}  valChange={this.emailChange} val={this.state.contactEmail} type={"email"}  name={"email"} label={"Email"}/>
                <FormField fieldID={"companyhero"}  valChange={this.companyChange} val={this.state.contactCompany} type={"text"}  name={"company"} label={"Company"}/>
                <FormField fieldID={"telephonehero"}  valChange={this.phoneChange} val={this.state.contactPhone} type={"tel"} name={"telephone"} label={"Telephone"}/>
                <button type="submit" className="btn btn-white">SUBMIT</button>
                <div className="form-group">
                    <input type="hidden" name="referrer" value="https://www.meritmile.com/microsoft-csp/" />
                    <input type="hidden" name="auth" value="0b6196a8-cef3-4556-a87b-a389d1a86103" />
                    <input type="text" className="d-none hu" id="human-check" name="human-check" />
                    <div className="feedback"></div>
                    <div className="fix"></div>
                </div>
            </form>
        )
    }
}

export default ({pageContext}) => (
    <LayoutLPOne>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
            />
        <div className={`${pageContext.slug} csp-template`}>
            <div className="lp-hero">
                <div className="container margin-b">
                    <div className="row margin-b">
                        <div className="col-md-8 hero-title">
                            <h1>Microsoft CSP<span className="bar"></span></h1>
                        </div>
                        <div className="col-md-4 hero-bg">
                            <div className="inside-hero text-center">
                                <h2>Download the Grow Your Microsoft CSP Business Presentation Now.</h2>
                                <div className="form-wrapper">
                                    <CSPForm />
                                    <div className="thankyou">
                                    <h4>Thank You</h4>
                                    <p>If the report does not immediately open please check your pop-up blocker. A copy will also be sent to your email.</p>
                                    <p>Add info@meritmile.com to your contacts to ensure future communications will not be sent to spam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <img src="https://www.meritmile.com/uploads/2020/02/csp-01@2x.png" alt="Microsoft CSP" className="img-responsive" />
                        </div>
                        <div className="col-12 col-md-7">
                            <p>Through our longstanding relationship with Microsoft and its channel partners, Merit Mile has developed various cloud services marketing programs to support go-to-market initiatives for global distributors, resellers, hosters, VAR’s, MSP’s, and ISV’s. Using our industry experience and expertise, we can provide you with the customized marketing services and resources you need to effectively sell Microsoft Azure, Office 365, Microsoft 365, and Microsoft Dynamics 365 and accelerate the growth of your Cloud Solution Provider (CSP) business. </p>
                            <p>Submit the form at the top of the page to review a variety of creative samples, key messaging strategies and tactics for prospecting, demand generation, and onboarding new Microsoft CSP partners and customers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h2>CSP Indirect Provider or Cloud Distributor</h2>
                            <p>As a Microsoft CSP Indirect Provider, do you have an effective partner recruitment strategy? Are you articulating the value of the vast opportunities that are available to cloud resellers? Are you clear about how you can support potential partners grow their cloud businesses through the Microsoft CSP program?</p>
                            <h3>We can help you –</h3>
                            <ul>
                                <li>Strategically define your CSP distribution offering and key differentiators.</li>
                                <li>Design best-in-class partner readiness portals, tools, and resources.</li>
                                <li>Create demand with partner recruitment programs using tested and proven tactics.</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-5">
                            <img src="https://www.meritmile.com/uploads/2020/02/csp-02@2x.png" alt="Microsoft CSP" className="img-responsive" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="videowrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 order-md-12 col-lg-6">
                            <h2>CSP Direct Provider or Indirect Reseller</h2>
                            <p>As a Microsoft CSP direct provider or indirect reseller, do you have a reliable demand generation strategy? Is your cloud value message clear and resonating with potential customers? Is your cloud business growing and are you profitable?</p>
                            <h3>We can help you –</h3>
                            <ul>
                                <li>Clearly articulate your cloud value proposition and differentiate your offerings.</li>
                                <li>Drive demand with insightful lead generation and content marketing strategies.</li>
                                <li>Close more deals with tools and sales techniques that help control the customer conversation.</li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 order-md-1 col-lg-4">
                            <img src="https://www.meritmile.com/uploads/2020/02/csp-03@2x.png" alt="Microsoft CSP" className="img-responsive" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-10 offset-md-1">
                            <p className="">Please submit the form at the top of the page to download Merit Mile's key strategies and tactics to Grow Your Microsoft CSP Business presentation now.</p>
                            <p className="disclaimer">Submitting the form gives us consent to send you emails which, of course, you are welcome to opt-out of at any time.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="graywrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <p className="maxbtnwidth">Learn more about our technology marketing expertise at</p>
                            <p><a href="/technology-marketing/" className="btn btn-secondary">www.meritmile.com/technology-marketing</a></p>
                            <p className="maxbtnwidth">and our experience in marketing for Microsoft Services Provider Licensing Agreement (also known as Microsoft SPLA) resellers and service providers at</p>
                            <p><a href="/microsoft-spla/" className="btn btn-secondary">www.meritmile.com/microsoft-spla</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutLPOne>
)